<template>
  <div class="row py-5">
    <div class="register card">
      <article class="card-body">
        <h4 class="card-title text-center mb-4 mt-1">Create an account</h4>
        <hr />
        <ValidationObserver v-slot="{ handleSubmit }">
        <form ref="form" @submit.prevent="handleSubmit(register)">
          <div class="form-group">
            <ValidationProvider name="FirstName" rules="required" v-slot="{ errors }">
              <label for="firstname">First Name</label>
              <input name="firstname" v-model="user.firstName" class="form-control" type="text" />
              <span  class="text-danger form-text">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <ValidationProvider name="LastName" rules="required" v-slot="{ errors }">
              <label for="lastname">Last Name / Surname</label>
              <input name="lastname" v-model="user.lastName" class="form-control" type="text" />
              <span  class="text-danger form-text">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <ValidationProvider name="Email" rules="required|email" mode="eager" v-slot="{ errors }">
              <label for="email">Email</label>
              <input name="email" v-model="user.email" class="form-control" type="email" />
              <span  class="text-danger form-text">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <ValidationProvider name="Password" rules="required" mode="eager" v-slot="{ errors }">
              <label for="password">Password</label>
              <input name="password" v-model="user.password" class="form-control" type="password" />
              <span class="text-danger form-text">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <ValidationProvider name="ConfirmPassword" rules="required|confirmed:Password" mode="eager" v-slot="{ errors }">
              <label for="confirmPassword">Confirm Password</label>
              <input name="confirmPassword" v-model="user.confirmPassword" class="form-control" type="password" />
              <span class="text-danger form-text">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <button type="submit" class="btn btn-primary btn-block" :disabled="loading">
              <b-spinner small v-if="loading"> </b-spinner>
              Register
            </button>
          </div>
        </form>
        </ValidationObserver>
        <p>Already Registered? <router-link :to="{ name: 'login' }">Login</router-link></p>
        <p>By registering, you agree to our <router-link :to="{ name: 'policy' }">privacy policy</router-link>.</p>
      </article>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import '@/util/validation';


export default {
  name: 'Register',
  components: {
    ValidationProvider, ValidationObserver,
  },
  data() {
    return {
      user: {},
      loading: false,
    };
  },
  computed: {
    displayName() {
      return `${this.user.firstName} ${this.user.lastName}`;
    },
  },
  methods: {
    register() {
      this.loading = true;
      const { user } = this;
      user.displayName = this.displayName;
      this.$store.dispatch('auth/register', user).then(() => {
        this.loading = false;
        this.$router.replace({ name: 'login' });
      }).catch(() => {
        this.$toast.error('Registration failed. Please, try again later.');
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.register
  margin 0 auto
  min-width 21rem
</style>
