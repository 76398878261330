var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row py-5"},[_c('div',{staticClass:"register card"},[_c('article',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title text-center mb-4 mt-1"},[_vm._v("Create an account")]),_c('hr'),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.register)}}},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"FirstName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"firstname"}},[_vm._v("First Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.firstName),expression:"user.firstName"}],staticClass:"form-control",attrs:{"name":"firstname","type":"text"},domProps:{"value":(_vm.user.firstName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "firstName", $event.target.value)}}}),_c('span',{staticClass:"text-danger form-text"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"LastName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"lastname"}},[_vm._v("Last Name / Surname")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.lastName),expression:"user.lastName"}],staticClass:"form-control",attrs:{"name":"lastname","type":"text"},domProps:{"value":(_vm.user.lastName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "lastName", $event.target.value)}}}),_c('span',{staticClass:"text-danger form-text"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"email"}},[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.email),expression:"user.email"}],staticClass:"form-control",attrs:{"name":"email","type":"email"},domProps:{"value":(_vm.user.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "email", $event.target.value)}}}),_c('span',{staticClass:"text-danger form-text"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"Password","rules":"required","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"password"}},[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.password),expression:"user.password"}],staticClass:"form-control",attrs:{"name":"password","type":"password"},domProps:{"value":(_vm.user.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "password", $event.target.value)}}}),_c('span',{staticClass:"text-danger form-text"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"ConfirmPassword","rules":"required|confirmed:Password","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"confirmPassword"}},[_vm._v("Confirm Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.confirmPassword),expression:"user.confirmPassword"}],staticClass:"form-control",attrs:{"name":"confirmPassword","type":"password"},domProps:{"value":(_vm.user.confirmPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "confirmPassword", $event.target.value)}}}),_c('span',{staticClass:"text-danger form-text"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('button',{staticClass:"btn btn-primary btn-block",attrs:{"type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Register ")],1)])])]}}])}),_c('p',[_vm._v("Already Registered? "),_c('router-link',{attrs:{"to":{ name: 'login' }}},[_vm._v("Login")])],1),_c('p',[_vm._v("By registering, you agree to our "),_c('router-link',{attrs:{"to":{ name: 'policy' }}},[_vm._v("privacy policy")]),_vm._v(".")],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }